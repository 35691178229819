import React, {useEffect, useState} from 'react';

import cn from 'classnames';
import moment from 'moment';
import {IMaskInput} from 'react-imask';

import bem from 'client/services/bem';
import {parseDate} from 'client/services/utils/date';

import Icon from 'client/common/icon';

import {Translation} from 'client/models/language/types';

import {DISPLAYED_FORMAT} from '../constants';

import cssModule from './datepicker-input.module.scss';

type DateInputProps = {
  value?: string;
  outputFormat?: 'DATE' | 'ISO';
  placeholder?: Translation;
  name?: string;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (date?: string) => void;
  onTyped?: (str: string) => void;
  className?: string;
  status?: 'error' | 'warning';
  hideIcon?: boolean;
};

const b = bem('datepicker-input', {cssModule});

const DATE_MASK = '00/00/0000';

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>((props, ref) => {
  const {name, value, onFocus, onBlur, onChange, onTyped, className, disabled, placeholder, status, hideIcon} = props;

  const [term, setTerm] = useState(parseDate(value, {outputFormat: 'DATE'}) || '');

  useEffect(() => {
    setTerm(parseDate(value, {outputFormat: 'DATE'}) || '');
  }, [value]);

  const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const nextValue = e.target.value;
    if (!moment(nextValue, DISPLAYED_FORMAT, true).isValid()) {
      if (nextValue !== value) {
        onChange?.();
      }
      setTerm('');
    }
    onBlur?.();
  };

  const handleInputChange = (nextTerm: string) => {
    setTerm(nextTerm);
    onTyped?.(nextTerm);

    if (!nextTerm || moment(nextTerm, DISPLAYED_FORMAT, true).isValid()) {
      onChange?.(nextTerm);
    }
  };

  return (
    <div className={cn(b([status]), className)}>
      <IMaskInput
        name={name}
        inputRef={ref}
        className={b('input', [status])}
        onFocus={onFocus}
        onBlur={handleInputBlur}
        onAccept={handleInputChange}
        disabled={disabled}
        value={term}
        mask={DATE_MASK}
        lazy
        placeholder={placeholder?.toString() || DISPLAYED_FORMAT.toLowerCase()}
      />

      {!hideIcon && <Icon name="calendar" className={b('icon')} />}
    </div>
  );
});
